lightboxElement = null
lightboxContent = null
lightboxProductionId = null
lightboxIndex = -1

VIDEO_FORMATS = ["webm", "mp4", "ogv"]

window.addEventListener("load", ()=> 

	smoothScroll.init()

	resize();


	window.addEventListener("resize", resize)

	document.body.classList.add("loaded")
	lightboxElement = document.querySelector("#lightbox-modal")
	lightboxContent = document.querySelector("#lightbox-content")

	# do Masonry

	isMobile = !!(navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i))

	# if we're not on mobile, load the video
	# if not isMobile
	# 	splashVideo = document.querySelector("#splashVideo")
		
	# 	srcString = splashVideo.getAttribute("data-src")
	# 	for i in VIDEO_FORMATS
	# 		videoSrc = document.createElement("source")
	# 		videoSrc.setAttribute("src", srcString + "." + i)
	# 		splashVideo.appendChild(videoSrc)

	# 	splashVideo.classList.add("visible")
	# 	splashVideo.play()
	# 	splashVideo.volume = 0
	if isMobile
		splashVideo = document.querySelector("#splashVideo")
		splashVideo.parentElement.removeChild(splashVideo)
)

# loadNextProduction = ()=>

# 	if (production_list.length)
# 		nextProduction = production_list.shift()

# 		console.log "loading #{nextProduction.getAttribute("id")}"

# 		# loop through images, replace 'src' with 'data-src'
# 		imageList = [].slice.call(nextProduction.querySelectorAll("img"))
# 		for img in imageList
# 			# insert scaling params from Cloudinary
# 			srcUrl = img.getAttribute("data-src")
# 			if (srcUrl.indexOf("cloudinary") != -1)
# 				srcUrl = srcUrl.replace("/image/upload/", "/image/upload/c_scale,w_" + CLOUDINARY_SCALE_WIDTH + "/")
# 			img.setAttribute("src", srcUrl)

# 		gridElement = nextProduction.querySelector(".grid")
# 		do(gridElement) ->
# 			imagesLoaded(gridElement, ()=>

# 				msnry_grids.push(new Masonry(gridElement, {
# 					itemSelector : ".grid-item",
# 					columnWidth : ".grid-sizer",
# 					percentPosition : true
# 				}))

# 				gridElement.classList.add("images-loaded")
# 				gridElement.parentElement.querySelector(".spinner").classList.remove("visible")

# 				# setup gallery for this production

				
# 				productionId = nextProduction.getAttribute("id")
# 				do (productionId) ->
# 					galleryData[productionId] = []
# 					images = gridElement.querySelectorAll(".grid-item.image")
# 					for img in images
						
# 						galleryData[productionId].push(img)

# 						img.addEventListener("click", (e)=>
# 								e.preventDefault()
# 								showLightboxImage(productionId, galleryData[productionId].indexOf(e.target))
# 								return false
# 							)
					
# 					videos = gridElement.querySelectorAll(".action-video")
# 					for video in videos
# 						do (video) ->
# 							video.addEventListener("click", (e)=>
# 								e.preventDefault()
# 								showLightboxVideo(productionId, video.getAttribute("data-action-data"), video.getAttribute("data-action-type"))
# 								return false
# 							)

# 				loadNextProduction()

# 				)

# 				# setup action(s) for this production

				

# 	else

# 		# setup lightbox left / right handlers
# 		$("#modal-next").click(()=>
# 			navigateLightboxImage(1)
# 			)
# 		$("#modal-previous").click(()=>
# 			navigateLightboxImage(-1)
# 			)

# 		document.body.addEventListener("keydown", onKeyDown)

# 		#handle modal closing
# 		$("#lightbox-modal").on("hidden.bs.modal", (e)=>
# 			lightboxContent.innerHTML = ""
# 			$("#modal-loading").removeClass("visible")
# 			)

# 		console.log "Load complete"


showLightboxImage = (productionId, index) =>

	lightboxProductionId = productionId
	lightboxIndex = index	
	
	lightboxContent.innerHTML = ""
	newImg = document.createElement("img")
	srcUrl = galleryData[productionId][index].getAttribute("data-src-fullsize")
	if (srcUrl.indexOf("cloudinary") != -1)
		srcUrl = srcUrl.replace("/image/upload/", "/image/upload/c_limit,h_" + CLOUDINARY_LIMIT_HEIGHT + "/")
	newImg.src = srcUrl
	lightboxContent.appendChild(newImg)

	$("#modal-loading").addClass("visible")
	imagesLoaded(lightboxContent, ()=>
		$("#modal-loading").removeClass("visible")
	)
	$("#lightbox-modal").modal('show')

showLightboxVideo = (productionId, url, videoType) =>

	lightboxProductionId = productionId
	lightboxIndex = 0

	lightboxContent.innerHTML = ""
	
	if videoType is "youtube"
		newIframe = document.createElement("iframe")
		newIframe.setAttribute("width", "100%")
		newIframe.setAttribute("src", "https://www.youtube.com/embed/#{url}?modestbranding=1&showinfo=0&rel=0")
		newIframe.setAttribute("frameborder", "0")
		newIframe.setAttribute("allowfullscreen", "true")

	else if videoType is "vimeo"
		newIframe = document.createElement("iframe")
		newIframe.setAttribute("width", "100%")
		newIframe.setAttribute("src", "https://player.vimeo.com/video/#{url}?title=0&badge=0&byline=0&portrait=0")
		newIframe.setAttribute("frameborder", "0")
		newIframe.setAttribute("allowfullscreen", "true")

	lightboxContent.appendChild(newIframe)

	$("#lightbox-modal").modal('show')

navigateLightboxImage = (direction)=>
	if not !!lightboxProductionId then return
	if not (lightboxIndex >= 0) then return
	if not $("body").hasClass("modal-open") then return

	nextIndex = lightboxIndex + direction
	productionGalleryData = galleryData[lightboxProductionId]
	if (nextIndex < 0) then nextIndex = galleryData[lightboxProductionId].length-1
	else if (nextIndex >= galleryData[lightboxProductionId].length) then nextIndex = 0

	# console.log("navigating lightbox image to #{lightboxProductionId} : index : #{nextIndex}")

	showLightboxImage(lightboxProductionId, nextIndex)

onKeyDown = (e)=>
	switch e.keyCode
		when 39
			#right arrow
			if $("body").hasClass("modal-open") then navigateLightboxImage(1)
		when 37
			#left arrow
			if $("body").hasClass("modal-open") then navigateLightboxImage(-1)
		when 27
			#escape
			if $("body").hasClass("modal-open") then $("#lightbox-modal").modal('hide')

resize = ()->


window.showSection = (id)->
	if rendering
		rendering = false
		_h.renderer.domElement.classList.add "hidden"

	sections = document.querySelectorAll ".section"
	for i in [0..sections.length-1] by 1
		sections[i].classList.remove "active"

	newSection = document.querySelector "#section-#{id}"
	if newSection
		newSection.classList.add "active"

	return false

window.resetRendering = ()->
	if !rendering
		rendering = true
		_h.renderer.domElement.classList.remove "hidden"
		update()

	sections = document.querySelectorAll ".section"
	for i in [0..sections.length-1] by 1
		sections[i].classList.remove "active"




